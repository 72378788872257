import objectPath from "object-path";
// import { lowerCase } from "lodash";
export default {
	data() {
		return {
			lists: [],
		};
	},
	methods: {
		getValue(object, path) {
			return objectPath.get(object, path);
		},
		hasKey(object, path) {
			return objectPath.has(object, path);
		},
		kpiColorIdentifier(half_yr, full_yr, current, month_passed) {
			let color;
			let monthPassed = month_passed;
			let kpi_value;

			if (monthPassed <= 7) {
				kpi_value = half_yr * (monthPassed / 6);
			} else {
				kpi_value = full_yr * (monthPassed / 12);
			}

			if (Math.ceil(kpi_value) <= current) {
				color = "green--text";
			} else {
				color = "red--text";
			}

			return color;
		},
		idnfColor(data) {
			let monthPassed = data.month_passed;
			let final_value = 0;
			let ranking_data = data.ranking_data;
			let to_customer = data.to_customer;

			if (ranking_data && to_customer) {
				if (monthPassed <= 7) {
					if (Math.ceil(to_customer?.kpi_6_month_top_3 * (monthPassed / 6)) <= ranking_data.top3) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_5 * (monthPassed / 6)) <= ranking_data.top5) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_10 * (monthPassed / 6)) <= ranking_data.top10) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_20 * (monthPassed / 6)) <= ranking_data.top20) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_50 * (monthPassed / 6)) <= ranking_data.top50) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_100 * (monthPassed / 6)) <= ranking_data.top100) {
						final_value += 1;
					}
				} else {
					if (Math.ceil(to_customer?.top_3 * (monthPassed / 12)) <= ranking_data.top3) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_5 * (monthPassed / 12)) <= ranking_data.top5) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_10 * (monthPassed / 12)) <= ranking_data.top10) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_20 * (monthPassed / 12)) <= ranking_data.top20) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_50 * (monthPassed / 12)) <= ranking_data.top50) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_100 * (monthPassed / 12)) <= ranking_data.top100) {
						final_value += 1;
					}
				}
			}

			return final_value < 6 ? "red" : "green";
		},
		statusColor(status) {
			switch (status) {
				case 1:
					return "green";
				case 2:
					return "orange";
				case 3:
					return "red";
			}
		},
		statusText(status) {
			switch (status) {
				case 1:
					return "Active";
				case 2:
					return "Hold";
				case 3:
					return "Closed";
			}
		},
	},
};
